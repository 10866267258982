import { isServer } from 'solid-js/web';
import { onCleanup, onMount } from 'solid-js';
import { revalidate } from '../graphql/cache';
import type { ParentProps } from 'solid-js';
import type { CacheKey } from '../graphql/cache';

type Props = ParentProps<{
	keys: CacheKey | Array<CacheKey> | void;
	timeout?: number;
}>;

const fourMinutes = 60_000 * 4;
let hiddenAt: number = -1;

export function Revalidator(props: Props) {
	const listener = isServer
		? async () => {}
		: async () => {
				if (document.visibilityState === 'hidden') {
					hiddenAt = Date.now();
					return;
				}

				if (document.visibilityState === 'visible' && hiddenAt > 0) {
					if (Date.now() - hiddenAt > (props.timeout || fourMinutes)) {
						await revalidate(props.keys);
					}
					hiddenAt = -1;
				}
			};

	onMount(() => {
		if (!isServer) {
			document.addEventListener('visibilitychange', listener);
		}
	});

	onCleanup(() => {
		if (!isServer) {
			document.removeEventListener('visibilitychange', listener);
		}
	});

	return props.children;
}
